import styled from 'styled-components';

export const ImageStyle = styled.img`
  width: 300px;
  height: auto;
  margin: 0 auto;
  border: 0;
  vertical-align: top;
  @media (max-width: 870px) {
    width: 220px;
  }
`;
export const HeaderH2 = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #929497;
  padding: 0;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  b {
    color: #00a7e9;
  }
  @media (max-width: 870px) {
    font-size: 21px;
    line-height: 34px;
    b {
      color: #00a7e9;
    }
  }
`;

export const ParagraphStyle = styled.p`
  font-size: 30px;
  line-height: 40px;
  color: #929497;
  padding: 0;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  b {
    color: #00a7e9;
  }
  code {
    font-size: 13px;
    line-height: 15px;
    color: #929497;
    padding: 0;
    font-family: 'Helvetica-Condensed', arial, sans-serif;
  }
  @media (max-width: 870px) {
    font-size: 21px;
    line-height: 34px;
    b {
      color: #00a7e9;
    }
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00447c;
  text-align: center;
  b {
    color: #00447c;
  }
`;

export const Header1 = styled.h1`
  font-size: 46px;
  line-height: 56px;
  padding: 25px 0 0 0;
  margin: 0 0 25px 0;
  font-family: 'MrAlex', arial, sans-serif;
  font-weight: normal !important;
  color: #00539f;
`;

export const Body = styled.div`
  margin: 0 auto !important;
  padding: 0;
  padding-top: 60px !important;
  text-align: center;
  padding-bottom: 30px;
  margin: 0;
  padding: 0;
  color: #00539f;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  @media (max-width: 870px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 25px;
  }
`;
